<template>
  <div
    class="spinner"
    :class="{
      'visible':  isVisible,
      'opaque': isOpaque}"
    v-show="isVisible" />
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
      isOpaque: false
    }
  },
  methods: {
    toggleSpinner(status) {
      if (status) {
        this.showSpinner()
      } else {
        this.hideSpinner()
      }
    },
    showSpinner() {
      this.isVisible = true
      this.isOpaque = true
    },
    hideSpinner() {      
      this.isOpaque = false
      setTimeout(() => {
        this.isVisible = false
      }, 200)
    }
  }
}
</script>

<style lang="scss">
.spinner {
  position: absolute;
  z-index: 100500;
  top: 0;
  left: 0;
  background:rgba(255,255,255,0.4) url('~@/resources/images/assets/preloader.gif') no-repeat center center;
  background-size: 100%;
  width: 100%;
  height: 100%;
  transition: all 0.2s;
  visibility: hidden;    
  opacity: 0;
  border-radius: 18px;
  &.visible {
    visibility: visible;
  }
  &.opaque {  
    opacity: 1;
  }
}
</style>