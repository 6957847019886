<template>  
  <form style="width: 100%;" :class="{'displayed' : isVisible}">
    <div class="card">
      <div class="card__line">
        <p>Email</p>
        <div class="card-input StripeElement--complete card-input_normal">
          <input :value="email" disabled />
        </div>
      </div>
      <div class="card__line">             
        <p>Card Holder Name</p>
        <DefaultInput
          :fieldName="'cardName'"
          @validateInput="validateField"
          @scrollWindow="scrollWindow"
          @resetScrollWindow="resetScrollWindow"
          @setInputValue="setInputValue"/>
      </div>
      <div class="card__line">                  
        <p>Card Number</p>
        <StripeInput :inputData="validationForm.cardNumber" :idModifier="'number'" />
      </div>
      <div class="card__line card__line_double">
        <p>Expiry Date</p> 
        <StripeInput :inputData="validationForm.cardExpiry" :idModifier="'expiry'" :classModifier="'left'" /> 
        <StripeInput :inputData="validationForm.cardCvc" :idModifier="'cvc'" :classModifier="'top'" />
      </div>
      <div 
        class="button card-button"
        :class="{'disabled': !stripeRequestAllowed || !validateForm()}"
        @click="createStripeRequest"
      >
        Start Free Trial
      </div>
      <Spinner ref="spinner"/>
    </div>
  </form>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import LandingServices from '@/services/LandingServices'
import StripeInput from './InputStripe'
import DefaultInput from './InputDefault'
import Spinner from '@/components/Spinner'

export default {
  data() {
    return {
      cardFormDisplayed: false,
      stripe: null,
      selectedInput: 0,
      cardName: '',
      stripeRequestAllowed: true,
      stripeLoaded: false,
      errors: [],
      formValidated: false,
      validationForm: {
        cardName: { status: false },
        cardExpiry: { status: false },
        cardNumber: { status: false },
        cardCvc: { status: false },
      },
      scrollWindowAllowed: true
    }
  },
  components: {
    StripeInput,
    DefaultInput,
    Spinner
  },
  props: {
    email: String,
    isVisible: Boolean
  },
  methods: {
    setInputValue({name, value}) {
      this.$data[name] = value.toUpperCase()
    },
    validateField(input) {
      this.validationForm[input.elementType] = input
      this.validateForm()
    },
    validateForm() {
      return Object.values(this.validationForm).every(input => {
        return input.complete && input.error === undefined && !input.empty
      })
    },
    initMetrikaGoal() {
      setTimeout(() => {
        if (window.ym) {
          window.ym(process.env.VUE_APP_YANDEX_ID,'reachGoal','stripe')
        } else {
          this.initMetrikaGoal()
        }
      }, 100);
    },
    async createStripeRequest(event) {
      event.preventDefault();      
      if (!this.validateForm()) {
        return false
      }      
      this.$refs.spinner.toggleSpinner(true)
      this.initMetrikaGoal()
      await window.fbq('track', 'Subscribe')
      this.stripeRequestAllowed = false
      const result = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.cardNumber
      })      
      this.handlePaymentMethodResult(result)
    },
    async handlePaymentMethodResult(result) {
      if (result.error) {
        this.$router.push({ name: 'Payment failed'})
      } else {
        this.stripeRequestAllowed = false
        const additionalData = {
          name: this.cardName
        }
        const temp = await this.stripe.createToken(this.cardNumber, additionalData)
        await LandingServices.checkoutCard({
          email: window.email, 
          token: temp.token.id,
          user_id: window.user_id
        }).then(() => {
          this.$router.push({ name: 'Payment succeed', params: { user_id: window.user_id }})
        }).catch(() => {
          this.$router.push({ name: 'Payment failed'})
        })
      }
      window.scrollTo(0,0)
      this.element._elements.forEach(element => element.destroy())      
      this.$refs.spinner.toggleSpinner(false)
      this.stripeRequestAllowed = true      
    },
    createStripeElements() {
      this.cardNumber = this.createStripeElement(
        this.element,
        "cardNumber",
        "#card-input__number"
      )
      this.cardExpiry = this.createStripeElement(
        this.element,
        "cardExpiry",
        "#card-input__expiry"
      )
      this.cardCvc = this.createStripeElement(
        this.element,
        "cardCvc",
        "#card-input__cvc"
      )
    },
    createStripeElement(element, name, nodeForMount) {
      const newElement = element.create(name, { style: stylesStripe })
      newElement.mount(nodeForMount)
      newElement.on('change', this.validateField)
      newElement.on('focus', this.resetScrollWindow)
      newElement.on('blur', this.scrollWindow)
      return newElement;
    },
    scrollWindow() {
      this.scrollWindowAllowed = true
      setTimeout(() => {
        if (this.scrollWindowAllowed) {
          window.scrollTo(0, 0)
        }
      }, 100)
    },
    resetScrollWindow() {
      this.scrollWindowAllowed = false
    }
  },

  async mounted() {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY)
    this.element = this.stripe.elements({locale: 'en'});
    this.stripeLoaded = false
    this.$nextTick(() => {
      this.createStripeElements()
    })
  }
}

const stylesStripe = {
    base: {
      padding: '0',
      // lineHeight: window.innerHeight < 630 ? '38px' : '42px',
      fontSize: '16px',
      fontFamily: "SF Pro Display, -apple-system, BlinkMacSystemFont, Helvetica, sans-serif",
      backgroundColor: '#FFF5F6',
      textTransform: 'uppercase',
      color: '#000000',
      ':-webkit-autofill': {
        color: '#000000',
      },
    },
    empty: {
      '::placeholder': {
          color: '#FFA2B0',
      },
    },
    invalid: {
      color: 'red',
    },
}
</script>